<template>
  <div id="main">
    <div class="container">
      <div class="accordion">
        <Collapse
          v-for="(item, index) in data"
          :key="index"
          :item="item"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
    Collapse: () => import('@/components/atoms/Collapse'),
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
#main {
  display: none;
}
@media (max-width: 479px) {
  #main {
    display: block;
  }
}
</style>
